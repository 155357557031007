$base-font-face: 'BlogFont';
$base-font-file-prefix: 'nr/nr';
$global-font-size: 18px;

$global-font-family: $base-font-face, system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;

$header-title-font-family: $base-font-face, system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
$header-title-font-size: 1rem;

$blockquote-color: #d3d6db;
// $single-link-color: #69707a;
