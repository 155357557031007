@font-face {
  font-family: $base-font-face;
  src: url('/fonts/#{$base-font-file-prefix}-light.woff2') format('woff2'),
    url('/fonts/#{$base-font-file-prefix}-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $base-font-face;
  src: url('/fonts/#{$base-font-file-prefix}-bold.woff2') format('woff2'),
    url('/fonts/#{$base-font-file-prefix}-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $base-font-face;
  src: url('/fonts/#{$base-font-file-prefix}-regular.woff2') format('woff2'),
    url('/fonts/#{$base-font-file-prefix}-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.header-title {
  font-weight: 400 !important;
}

.theme-switch i {
  transform: rotate(0deg);
}

.single {
  .single-title {
    margin: 1rem 0;
    font-size: 2rem;
  }

  .post-meta-line {
    padding: 0.2rem 0;
  }

  .content {
    .highlight {
      margin: 1rem 0;
    }

    blockquote {
      border-left: 0.2rem solid $blockquote-color;
    }

    code {
      line-break: auto !important;
      white-space: pre-wrap;
    }
  }

  .post-footer {
    .post-tags {
      color: #d3d6db;
    }
  }
}

footer {
  .footer-container {
    font-size: 0.7rem;
  }
}

code {
  border-radius: 4px;
}

#mc_embed_signup {
  padding: 20px 10px;
  text-align: left;
  margin: auto;
  border: solid 1px #2d96bd;
  margin-top: 50px;
  max-width: 500px;
  
  input[type='email'] {
    width: calc(100% - 250px);
    max-width: 300px;
    padding-right: 150px;
    padding-left: 20px;
    line-height: 2.5rem;
    margin-top: 10px;
  }

  input[type='submit'] {
    cursor: pointer;
    margin-left: -150px;
    line-height: 2.5rem;
    width: 150px;
    background: transparent;
    color: #2d96bd;
    border: 0;
    -webkit-appearance: none;
  }

  @media only screen and (max-width: 960px) {
    input[type='email'] {
      width: 80%;
      padding: 10px;
      line-height: 2rem;
    }

    input[type='submit'] {
      clear: left;
      display: block;
      text-align: center;
      margin: auto;
      line-height: 2rem;
      width: 200px;
      border: solid 1px $blockquote-color-dark;
      margin-top: 10px;
    }    
  }
}
